import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "src/components/shared/ScrollToTop";
import AppRoutes from "src/routes/AppRoutes";
import "src/globals.css";
import Image from "src/components/elements/Image";

const Layout = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-backgroundColor flex flex-col relative z-0">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] flex">
          <Image
            src={"https://wallpaperaccess.com/full/90278.jpg"} 
            alt={""} 
            effect={"blur"} 
            classes={"object-cover"}
          />
        </div>
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={900}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
};

export default Layout;
